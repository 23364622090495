/* tslint:disable */
/* eslint-disable */
/**
 * Dataland QA Service API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/qaservice/model';
// @ts-ignore
import type { QaReviewResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/qaservice/model';
// @ts-ignore
import type { QaStatus } from '../../../../../../org/dataland/datalandfrontend/openApiClient/qaservice/model';
/**
 * QaControllerApi - axios parameter creator
 * @export
 */
export const QaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assign a QA review status to a dataset.
         * @summary Assign a QA review status to a dataset.
         * @param {string} dataId 
         * @param {QaStatus} qaStatus 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQaStatus: async (dataId: string, qaStatus: QaStatus, comment?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('changeQaStatus', 'dataId', dataId)
            // verify required parameter 'qaStatus' is not null or undefined
            assertParamExists('changeQaStatus', 'qaStatus', qaStatus)
            const localVarPath = `/datasets/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (qaStatus !== undefined) {
                localVarQueryParameter['qaStatus'] = qaStatus;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a filtered and chronologically ordered list of relevant meta info on unreviewed datasets.
         * @summary Get relevant meta info on unreviewed datasets.
         * @param {Set<GetInfoOnPendingDatasetsDataTypesEnum>} [dataTypes] 
         * @param {Set<string>} [reportingPeriods] 
         * @param {string} [companyName] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoOnPendingDatasets: async (dataTypes?: Set<GetInfoOnPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (dataTypes) {
                localVarQueryParameter['dataTypes'] = Array.from(dataTypes);
            }

            if (reportingPeriods) {
                localVarQueryParameter['reportingPeriods'] = Array.from(reportingPeriods);
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (chunkSize !== undefined) {
                localVarQueryParameter['chunkSize'] = chunkSize;
            }

            if (chunkIndex !== undefined) {
                localVarQueryParameter['chunkIndex'] = chunkIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the number of unreviewed datasets based on filters.
         * @summary Get the number of unreviewed datasets based on filters.
         * @param {Set<GetNumberOfPendingDatasetsDataTypesEnum>} [dataTypes] 
         * @param {Set<string>} [reportingPeriods] 
         * @param {string} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfPendingDatasets: async (dataTypes?: Set<GetNumberOfPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/numberOfUnreviewedDatasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (dataTypes) {
                localVarQueryParameter['dataTypes'] = Array.from(dataTypes);
            }

            if (reportingPeriods) {
                localVarQueryParameter['reportingPeriods'] = Array.from(reportingPeriods);
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the QA review information of uploaded dataset for a given id.Users can get the review information of their own datasets.Admins and reviewer can get the review information for all datasets.
         * @summary Get the QA review information of an uploaded dataset for a given id.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQaReviewsByDataId: async (dataId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('getQaReviewsByDataId', 'dataId', dataId)
            const localVarPath = `/datasets/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QaControllerApi - functional programming interface
 * @export
 */
export const QaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Assign a QA review status to a dataset.
         * @summary Assign a QA review status to a dataset.
         * @param {string} dataId 
         * @param {QaStatus} qaStatus 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeQaStatus(dataId: string, qaStatus: QaStatus, comment?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeQaStatus(dataId, qaStatus, comment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.changeQaStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a filtered and chronologically ordered list of relevant meta info on unreviewed datasets.
         * @summary Get relevant meta info on unreviewed datasets.
         * @param {Set<GetInfoOnPendingDatasetsDataTypesEnum>} [dataTypes] 
         * @param {Set<string>} [reportingPeriods] 
         * @param {string} [companyName] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoOnPendingDatasets(dataTypes?: Set<GetInfoOnPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QaReviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoOnPendingDatasets(dataTypes, reportingPeriods, companyName, chunkSize, chunkIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.getInfoOnPendingDatasets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the number of unreviewed datasets based on filters.
         * @summary Get the number of unreviewed datasets based on filters.
         * @param {Set<GetNumberOfPendingDatasetsDataTypesEnum>} [dataTypes] 
         * @param {Set<string>} [reportingPeriods] 
         * @param {string} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNumberOfPendingDatasets(dataTypes?: Set<GetNumberOfPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNumberOfPendingDatasets(dataTypes, reportingPeriods, companyName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.getNumberOfPendingDatasets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the QA review information of uploaded dataset for a given id.Users can get the review information of their own datasets.Admins and reviewer can get the review information for all datasets.
         * @summary Get the QA review information of an uploaded dataset for a given id.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQaReviewsByDataId(dataId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QaReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQaReviewsByDataId(dataId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QaControllerApi.getQaReviewsByDataId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QaControllerApi - factory interface
 * @export
 */
export const QaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QaControllerApiFp(configuration)
    return {
        /**
         * Assign a QA review status to a dataset.
         * @summary Assign a QA review status to a dataset.
         * @param {string} dataId 
         * @param {QaStatus} qaStatus 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQaStatus(dataId: string, qaStatus: QaStatus, comment?: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeQaStatus(dataId, qaStatus, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a filtered and chronologically ordered list of relevant meta info on unreviewed datasets.
         * @summary Get relevant meta info on unreviewed datasets.
         * @param {Set<GetInfoOnPendingDatasetsDataTypesEnum>} [dataTypes] 
         * @param {Set<string>} [reportingPeriods] 
         * @param {string} [companyName] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoOnPendingDatasets(dataTypes?: Set<GetInfoOnPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: any): AxiosPromise<Array<QaReviewResponse>> {
            return localVarFp.getInfoOnPendingDatasets(dataTypes, reportingPeriods, companyName, chunkSize, chunkIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the number of unreviewed datasets based on filters.
         * @summary Get the number of unreviewed datasets based on filters.
         * @param {Set<GetNumberOfPendingDatasetsDataTypesEnum>} [dataTypes] 
         * @param {Set<string>} [reportingPeriods] 
         * @param {string} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfPendingDatasets(dataTypes?: Set<GetNumberOfPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getNumberOfPendingDatasets(dataTypes, reportingPeriods, companyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the QA review information of uploaded dataset for a given id.Users can get the review information of their own datasets.Admins and reviewer can get the review information for all datasets.
         * @summary Get the QA review information of an uploaded dataset for a given id.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQaReviewsByDataId(dataId: string, options?: any): AxiosPromise<QaReviewResponse> {
            return localVarFp.getQaReviewsByDataId(dataId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QaControllerApi - interface
 * @export
 * @interface QaControllerApi
 */
export interface QaControllerApiInterface {
    /**
     * Assign a QA review status to a dataset.
     * @summary Assign a QA review status to a dataset.
     * @param {string} dataId 
     * @param {QaStatus} qaStatus 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    changeQaStatus(dataId: string, qaStatus: QaStatus, comment?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Gets a filtered and chronologically ordered list of relevant meta info on unreviewed datasets.
     * @summary Get relevant meta info on unreviewed datasets.
     * @param {Set<GetInfoOnPendingDatasetsDataTypesEnum>} [dataTypes] 
     * @param {Set<string>} [reportingPeriods] 
     * @param {string} [companyName] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    getInfoOnPendingDatasets(dataTypes?: Set<GetInfoOnPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<QaReviewResponse>>;

    /**
     * Get the number of unreviewed datasets based on filters.
     * @summary Get the number of unreviewed datasets based on filters.
     * @param {Set<GetNumberOfPendingDatasetsDataTypesEnum>} [dataTypes] 
     * @param {Set<string>} [reportingPeriods] 
     * @param {string} [companyName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    getNumberOfPendingDatasets(dataTypes?: Set<GetNumberOfPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * Get the QA review information of uploaded dataset for a given id.Users can get the review information of their own datasets.Admins and reviewer can get the review information for all datasets.
     * @summary Get the QA review information of an uploaded dataset for a given id.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApiInterface
     */
    getQaReviewsByDataId(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<QaReviewResponse>;

}

/**
 * QaControllerApi - object-oriented interface
 * @export
 * @class QaControllerApi
 * @extends {BaseAPI}
 */
export class QaControllerApi extends BaseAPI implements QaControllerApiInterface {
    /**
     * Assign a QA review status to a dataset.
     * @summary Assign a QA review status to a dataset.
     * @param {string} dataId 
     * @param {QaStatus} qaStatus 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public changeQaStatus(dataId: string, qaStatus: QaStatus, comment?: string, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).changeQaStatus(dataId, qaStatus, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a filtered and chronologically ordered list of relevant meta info on unreviewed datasets.
     * @summary Get relevant meta info on unreviewed datasets.
     * @param {Set<GetInfoOnPendingDatasetsDataTypesEnum>} [dataTypes] 
     * @param {Set<string>} [reportingPeriods] 
     * @param {string} [companyName] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public getInfoOnPendingDatasets(dataTypes?: Set<GetInfoOnPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).getInfoOnPendingDatasets(dataTypes, reportingPeriods, companyName, chunkSize, chunkIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the number of unreviewed datasets based on filters.
     * @summary Get the number of unreviewed datasets based on filters.
     * @param {Set<GetNumberOfPendingDatasetsDataTypesEnum>} [dataTypes] 
     * @param {Set<string>} [reportingPeriods] 
     * @param {string} [companyName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public getNumberOfPendingDatasets(dataTypes?: Set<GetNumberOfPendingDatasetsDataTypesEnum>, reportingPeriods?: Set<string>, companyName?: string, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).getNumberOfPendingDatasets(dataTypes, reportingPeriods, companyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the QA review information of uploaded dataset for a given id.Users can get the review information of their own datasets.Admins and reviewer can get the review information for all datasets.
     * @summary Get the QA review information of an uploaded dataset for a given id.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QaControllerApi
     */
    public getQaReviewsByDataId(dataId: string, options?: RawAxiosRequestConfig) {
        return QaControllerApiFp(this.configuration).getQaReviewsByDataId(dataId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetInfoOnPendingDatasetsDataTypesEnum = {
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    NuclearAndGas: 'nuclear-and-gas',
    Lksg: 'lksg',
    P2p: 'p2p',
    Sfdr: 'sfdr',
    Vsme: 'vsme',
    EsgDatenkatalog: 'esg-datenkatalog',
    Heimathafen: 'heimathafen',
    AdditionalCompanyInformation: 'additional-company-information'
} as const;
export type GetInfoOnPendingDatasetsDataTypesEnum = typeof GetInfoOnPendingDatasetsDataTypesEnum[keyof typeof GetInfoOnPendingDatasetsDataTypesEnum];
/**
 * @export
 */
export const GetNumberOfPendingDatasetsDataTypesEnum = {
    EutaxonomyFinancials: 'eutaxonomy-financials',
    EutaxonomyNonFinancials: 'eutaxonomy-non-financials',
    NuclearAndGas: 'nuclear-and-gas',
    Lksg: 'lksg',
    P2p: 'p2p',
    Sfdr: 'sfdr',
    Vsme: 'vsme',
    EsgDatenkatalog: 'esg-datenkatalog',
    Heimathafen: 'heimathafen',
    AdditionalCompanyInformation: 'additional-company-information'
} as const;
export type GetNumberOfPendingDatasetsDataTypesEnum = typeof GetNumberOfPendingDatasetsDataTypesEnum[keyof typeof GetNumberOfPendingDatasetsDataTypesEnum];
